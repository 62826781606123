import React, { Component } from "react";
import AuthService from "./AuthService";
import { API_BASE_URL } from "../../config";

export default function withAuth(AuthComponent) {
  const Auth = new AuthService(API_BASE_URL);

  return class AuthWrapped extends Component {
    constructor(props) {
      super(props);
      this.state = {
        user: null,
      };
    }
    componentDidMount() {
      if (!Auth.loggedIn()) {
        this.props.history.push({
          pathname: "/",
          state: {
            redirectAfterLogin: this.props.history.location.pathname,
          },
        });
      } else {
        try {
          const profile = Auth.getProfile();
          this.setState({
            user: profile,
          });
        } catch (err) {
          Auth.logout();
          this.props.history.replace("/");
        }
      }
    }
    render() {
      if (this.state.user) {
        return <AuthComponent history="/" user={this.state.user} />;
      } else {
        return null;
      }
    }
  };
}
