import React, { Component } from "react";
import {
  Responsive,
  Popup,
  Dropdown,
  Menu,
  Button,
  Modal,
} from "semantic-ui-react";
import AuthService from "../Authentication/AuthService";
import ProfileEdit from "../Profile/ProfileEdit";
import "./Header.css";
import { withRouter } from "react-router-dom";
import { HelpButton } from "./HelpButton";
import { DownloadSupportButton } from "./DownloadSupportButton";

const Auth = new AuthService();

class Header extends Component {
  constructor(props) {
    super(props);
    this.handleLogout = this.handleLogout.bind(this);
    this.handleNav = this.handleNav.bind(this);
    this.getHomeButton = this.getHomeButton.bind(this);
    this.getAdminButton = this.getAdminButton.bind(this);
    this.getProfileButton = this.getProfileButton.bind(this);
    this.getLogoutButton = this.getLogoutButton.bind(this);
    this.toggleProfile = this.toggleProfile.bind(this);

    this.state = {
      profileOpen: false,
    };

    // We want the user to navigate to the login page when the session expires.
    Auth.addLogoutHandler(this.handleLoginExpiration.bind(this));
  }

  /* 
  Called when our login expires.
  */
  handleLoginExpiration() {
    this.handleNav("login");
  }

  handleNav(nav) {
    this.props.history.push("/" + nav);
  }

  toggleProfile() {
    this.setState({ profileOpen: !this.state.profileOpen });
  }

  handleLogout() {
    Auth.logout();
    window.location.href = "/";
  }

  getHomeButton(isDropdown) {
    if (Auth.loggedIn()) {
      if (isDropdown) {
        return (
          <Dropdown.Item
            icon="home"
            text="Home"
            onClick={() => this.handleNav("")}
          />
        );
      } else {
        return (
          <Button
            basic
            inverted
            compact
            icon="home"
            onClick={() => this.handleNav("")}
          />
        );
      }
    }
  }

  getAdminButton(isDropdown) {
    if (Auth.loggedIn() && Auth.isAdmin()) {
      if (isDropdown) {
        return (
          <Dropdown.Item
            icon="settings"
            text="Administration"
            onClick={() => this.handleNav("admin")}
          />
        );
      } else {
        return (
          <Button
            basic
            inverted
            compact
            icon="settings"
            onClick={() => this.handleNav("admin")}
          />
        );
      }
    } else {
      return false;
    }
  }

  getProfileButton(isDropdown) {
    if (Auth.loggedIn()) {
      if (isDropdown) {
        return (
          <Dropdown.Item
            icon="user outline"
            text="Profile"
            onClick={() => this.toggleProfile()}
          />
        );
      } else {
        return (
          <Button
            basic
            inverted
            compact
            icon="user outline"
            onClick={() => this.toggleProfile()}
          />
        );
      }
    }
  }

  getLogoutButton(isDropdown) {
    if (Auth.loggedIn()) {
      if (isDropdown) {
        return (
          <Dropdown.Item
            direction="right"
            text="Sign Out"
            onClick={this.handleLogout}
          />
        );
      } else {
        return (
          <Button basic inverted compact onClick={this.handleLogout}>
            {" "}
            Sign Out{" "}
          </Button>
        );
      }
    }
  }

  render() {
    return (
      <div>
        <div className="headerBackground">
          <div className="header1">
            <span> The Way International &trade; </span> <br />
            <span className="header2">
              Biblical Research, Teaching, and Fellowship{" "}
            </span>{" "}
          </div>{" "}
          <div className="foundedLeft"> </div>{" "}
          <div className="foundedCenter"> Founded 1942 </div>{" "}
          <div className="foundedRight"> </div>{" "}
          <Responsive as={Menu.Menu} maxWidth={514} className="buttonDropdown">
            {Auth.loggedIn() && (
              <Dropdown
                icon="bars"
                floating
                button
                className="icon inverted white"
                direction="left"
              >
                <Dropdown.Menu>
                  {this.getHomeButton(true)}
                  {this.getAdminButton(true)}
                  {this.getProfileButton(true)}
                  <HelpButton as={Dropdown.Item} />
                  <DownloadSupportButton />
                  {this.getLogoutButton(true)}
                </Dropdown.Menu>
              </Dropdown>
            )}
            {!Auth.loggedIn() && <HelpButton as={Dropdown} />}
          </Responsive>
          <Responsive minWidth={515} className="buttonDiv">
            <span className="buttonHome">
              <Popup
                trigger={this.getHomeButton(false)}
                content="Home"
                position="bottom center"
                size="tiny"
                on="hover"
              />
            </span>
            <span className="buttonAdmin">
              <Popup
                trigger={this.getAdminButton(false)}
                content="Administration"
                position="bottom center"
                size="tiny"
                on="hover"
              />
            </span>
            <span className="buttonProfile">
              <Popup
                trigger={this.getProfileButton(false)}
                content="Profile"
                position="bottom center"
                size="tiny"
                on="hover"
              />
            </span>
            <span className={Auth.loggedIn() ? "buttonHelp" : "buttonDropdown"}>
              <Popup
                trigger={<HelpButton />}
                content="Help"
                position="bottom center"
                size="tiny"
                on="hover"
              />
            </span>
            <span className="buttonLogout">{this.getLogoutButton(false)}</span>
          </Responsive>{" "}
          <Modal open={this.state.profileOpen}>
            <Modal.Header>Update Profile</Modal.Header>
            <Modal.Content>
              <Modal.Description>
                <ProfileEdit />
              </Modal.Description>
            </Modal.Content>
            <Modal.Actions>
              <Button onClick={() => this.toggleProfile()}>Done</Button>
            </Modal.Actions>
          </Modal>
        </div>
      </div>
    );
  }
}

export default withRouter(Header);
