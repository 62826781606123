import decode from "jwt-decode";
import ServiceBase from "../../ServiceBase";
import { isEmpty } from "lodash";

export default class AuthService extends ServiceBase {
  // Initializing important variables
  constructor(domain) {
    super(domain);
    this.login = this.login.bind(this);
    this.getProfile = this.getProfile.bind(this);
  }

  login(username, password) {
    if (isEmpty(username?.trim()) || isEmpty(password?.trim())) {
      var err = {};
      err.message = "Please enter a username and password.";
      return Promise.reject(err);
    }

    // Get a token from api server using the fetch api
    return this.fetch(`${this.domain}/login`, {
      method: "POST",
      body: JSON.stringify({
        username: username,
        password: password,
      }),
    }).then((res) => {
      this.setToken(res.token); // Setting the token in localStorage
      // Logged in. Ensure we get logged out when our session expires.
      this.debounceExpiration();
      return res;
    });
  }

  setToken(idToken) {
    // Saves user token to localStorage
    localStorage.setItem("id_token", idToken);
  }

  logout() {
    // Clear user token and profile data from localStorage
    localStorage.removeItem("id_token");
  }

  getProfile() {
    // Using jwt-decode npm package to decode the token
    return decode(this.getToken());
  }

  getRoles() {
    var payload = decode(this.getToken());
    return payload["roles"];
  }

  isAdmin() {
    var roles = [];
    roles.push("ROLE_ADMIN");
    roles.push("ROLE_SUPER_ADMIN");
    roles.push("ROLE_CONTENT_MANAGER");
    roles.push("ROLE_CLASS_STREAMING");

    var userRoles = this.getRoles();
    return roles.some((r) => userRoles.includes(r));
  }
}
