import React, { Component } from "react";
import "./Footer.css";

class Footer extends Component {
  constructor(props) {
    super(props);
    this.getYear = this.getYear.bind(this);
  }

  getYear() {
    let d = new Date();
    return d.getFullYear();
  }

  render() {
    return (
      <div>
        <footer className="footer">
          <p className="footerText">
            <span id="thewaylink">
              © {this.getYear()} by{" "}
              <a
                href="https://www.theway.org/"
                alt="www.theway.org"
                title="Visit theway.org"
                target="_blank"
              >
                The Way International
              </a>
              ™.&nbsp;&nbsp;All rights reserved.
            </span>{" "}
            |&nbsp;
            <a
              href="https://www.theway.org/terms-conditions-and-privacy/"
              target="_blank"
            >
              Terms, Conditions, and Privacy
            </a>
          </p>
        </footer>
      </div>
    );
  }
}

export default Footer;
