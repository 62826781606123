import React from "react";
import { Dropdown } from "semantic-ui-react";

export function DownloadSupportButton(props) {
  return (
    <Dropdown.Item
      as="a"
      href="mailto:downloadsupport@theway.org"
      icon="mail"
      text="Download Support"
    />
  );
}
