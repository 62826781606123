import React, { Component } from "react";
import {
  Button,
  Grid,
  Container,
  Form,
  FormGroup,
  FormField,
  Input,
  Label,
} from "semantic-ui-react";
import "./Profile.css";
import { isEmpty } from "lodash";
import ProfileService from "./ProfileService";
import AdminService from "../Admin/AdminService";

const ProfileSvc = new ProfileService();
const AdminSvc = new AdminService();

class ProfileEdit extends Component {
  constructor(props) {
    super(props);

    this.state = {
      errorMessage: "",
      infoMessage: "",
      error: false,
      isLoading: true,
      profile: null,
      editingSelf: true,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleNavHome = this.handleNavHome.bind(this);
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
  }

  componentDidMount() {
    if (isNaN(this.props.id)) {
      ProfileSvc.getProfile()
        .then((profile) => {
          this.setState({
            profile: profile,
            isLoading: false,
          });
        })
        .catch((err) => {
          console.log(err);
          this.setState({ isLoading: false });
        });
    } else {
      AdminSvc.getUser(this.props.id)
        .then((profile) => {
          const self = AdminSvc.getUsername();

          this.setState({
            profile: profile,
            isLoading: false,
            editingSelf: self === profile.userName,
          });
        })
        .catch((err) => {
          console.log(err);
          this.setState({ isLoading: false });
        });
    }
  }

  handleChange(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  handleFormSubmit(e) {
    e.preventDefault();
    this.setState({
      isLoading: true,
      error: false,
      errorMessage: "",
    });

    if (this.state.password1 !== this.state.password2) {
      this.setState({
        errorMessage: "Passwords do not match.",
        isLoading: false,
      });
      return;
    }

    if (isNaN(this.props.id)) {
      ProfileSvc.updateProfile(
        this.state.profile,
        this.state.password1,
        this.state.passwordQuestion,
        this.state.passwordAnswer,
        this.state.email
      )
        .then(() => {
          this.setState({
            infoMessage: "Profile updated.",
            isLoading: false,
          });
        })
        .catch((err) => {
          var errorMessage = "Profile update unsuccessful.\nTry again later.";
          if (!isEmpty(err.message)) {
            errorMessage = err.message;
          }

          this.setState({
            errorMessage: errorMessage,
            isLoading: false,
          });
        });
    } else {
      AdminSvc.updateUser(
        this.state.profile,
        this.state.password1,
        this.state.passwordQuestion,
        this.state.passwordAnswer,
        this.state.email,
        this.state.mailKey,
        this.state.userName
      )
        .then(() => {
          this.setState({
            infoMessage: "User updated.",
            isLoading: false,
          });
        })
        .catch((err) => {
          var errorMessage = "User update unsuccessful.\nTry again later.";
          if (!isEmpty(err.message)) {
            errorMessage = err.message;
          }

          this.setState({
            errorMessage: errorMessage,
            isLoading: false,
          });
        });
    }
  }

  handleNavHome(e) {
    this.props.history.push("/");
  }

  render() {
    return (
      <div className="app flex-row align-items-center">
        <br />
        <Container>
          <Grid className="centered">
            <Form
              error={this.state.error}
              onSubmit={this.handleFormSubmit}
              className="profileForm"
            >
              {(!this.props.id || this.state.editingSelf) && (
                <>
                  <Grid column={2}>
                    <Grid.Column className="profileColumn">
                      <Label color="green" horizontal>
                        Customer Number
                      </Label>
                      <br />
                      <br />
                      <Label color="green" horizontal>
                        Username
                      </Label>
                    </Grid.Column>
                    <Grid.Column className="profileColumn">
                      {this.state.profile ? this.state.profile.mailKey : ""}
                      <br />
                      <br />
                      {this.state.profile ? this.state.profile.userName : ""}
                    </Grid.Column>
                  </Grid>
                  <br />
                  <br />
                </>
              )}
              {this.props.id && !this.state.editingSelf && (
                <>
                  <Grid.Row>
                    <FormGroup widths="equal">
                      <FormField required>
                        <label>Customer Number</label>
                        <Input
                          type="text"
                          name="mailKey"
                          placeholder="Customer Number"
                          onChange={this.handleChange}
                          width={6}
                          defaultValue={
                            this.state.profile ? this.state.profile.mailKey : ""
                          }
                          className="monoFont"
                        />
                      </FormField>
                    </FormGroup>
                  </Grid.Row>
                  <Grid.Row>
                    <FormGroup widths="equal">
                      <FormField required>
                        <label>Username</label>
                        <Input
                          type="text"
                          name="userName"
                          placeholder="Username"
                          onChange={this.handleChange}
                          width={6}
                          defaultValue={
                            this.state.profile
                              ? this.state.profile.userName
                              : ""
                          }
                          className="monoFont"
                        />
                      </FormField>
                    </FormGroup>
                  </Grid.Row>
                </>
              )}
              <Grid.Row>
                <FormGroup widths="equal">
                  <FormField required>
                    <label>Email Address</label>
                    <Input
                      type="email"
                      name="email"
                      placeholder="Email"
                      onChange={this.handleChange}
                      width={6}
                      defaultValue={
                        this.state.profile ? this.state.profile.email : ""
                      }
                    />
                  </FormField>
                </FormGroup>
              </Grid.Row>
              <Grid.Row>
                <FormGroup widths="equal">
                  <FormField error={this.state.error} required>
                    <label>New Password</label>
                    <Input
                      type="password"
                      name="password1"
                      placeholder="Password"
                      onChange={this.handleChange}
                    />
                  </FormField>
                  <FormField required>
                    <label>Confirm New Password</label>
                    <Input
                      type="password"
                      name="password2"
                      placeholder="Password"
                      onChange={this.handleChange}
                    />
                  </FormField>
                </FormGroup>
              </Grid.Row>
              <Grid.Row>
                <FormGroup widths="equal">
                  <FormField required>
                    <label>Password Recovery Question</label>
                    <Input
                      type="text"
                      name="passwordQuestion"
                      placeholder="Question"
                      onChange={this.handleChange}
                      defaultValue={
                        this.state.profile
                          ? this.state.profile.passwordQuestion
                          : ""
                      }
                    />
                  </FormField>
                </FormGroup>
              </Grid.Row>
              <Grid.Row>
                <FormGroup widths="equal">
                  <FormField required>
                    <label>Password Recovery Answer</label>
                    <Input
                      type="text"
                      name="passwordAnswer"
                      placeholder="Answer"
                      onChange={this.handleChange}
                      defaultValue={
                        this.state.profile
                          ? this.state.profile.passwordAnswer
                          : ""
                      }
                    />
                  </FormField>
                </FormGroup>
              </Grid.Row>
              <Grid.Row>
                <center>
                  <Button
                    primary
                    className="buttonStyle"
                    onClick={this.onSubmit}
                    block
                    loading={this.state.isLoading}
                  >
                    Update
                  </Button>
                </center>
              </Grid.Row>
              <Grid.Row>
                <br />
                {this.state.errorMessage !== "" && (
                  <div>
                    <p className="updateError">{this.state.errorMessage}</p>
                  </div>
                )}
                {this.state.infoMessage !== "" && (
                  <div>
                    <p className="updateInfo">{this.state.infoMessage}</p>
                  </div>
                )}
              </Grid.Row>
            </Form>
          </Grid>
        </Container>
      </div>
    );
  }
}

export default ProfileEdit;
