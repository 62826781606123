import React, { Component } from "react";
import { Router, Route } from "react-router-dom";
import { Loader } from "semantic-ui-react";
import withAuth from "./components/Authentication/WithAuth";
import { createBrowserHistory } from "history";
import "./App.css";
import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
import Login from "./components/Login/Login";

const history = createBrowserHistory();
const Home = React.lazy(() => import("./components/Home/Home"));
const Dashboard = React.lazy(() => import("./components/Dashboard/Dashboard"));
const Registration = React.lazy(() =>
  import("./components/Registration/Registration")
);
const Admin = React.lazy(() => import("./components/Admin/Admin"));
const Forgot = React.lazy(() => import("./components/Registration/Forgot"));
const Reset = React.lazy(() => import("./components/Registration/Reset"));
const Verify = React.lazy(() => import("./components/Registration/Verify"));
const Downloads = React.lazy(() => import("./components/Downloads/Downloads"));
const ClassStreaming = React.lazy(() =>
  import("./components/ClassStreaming/ClassStreaming")
);

const loadingSemantic = (
  <div className="globalComponentDiv">
    <Loader active />
  </div>
);

class App extends Component {
  render() {
    return (
      <Router history={history}>
        <Header />
        <div className="divCenter">
          <React.Suspense fallback={loadingSemantic}>
            <Route path="/" exact component={Home} />
            <Route path="/register" component={Registration} />
            <Route path="/forgot" component={Forgot} />
            <Route path="/reset/:token" component={Reset} />
            <Route path="/verify" component={Verify} />
            <Route path="/login" component={Login} />
            <Route path="/dashboard" component={withAuth(Dashboard)} />
            <Route path="/admin" component={withAuth(Admin)} />
            <Route path="/downloads" component={withAuth(Downloads)} />
            <Route
              exact
              path={[
                "/class-streaming",
                "/class-streaming/:classAbbr/:setNumber/:code",
              ]}
              component={withAuth(ClassStreaming)}
            />
          </React.Suspense>
        </div>
        <Footer />
      </Router>
    );
  }
}

export default App;
