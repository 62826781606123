import { isEmpty } from "lodash";
import ServiceBase from "../../ServiceBase";

export default class AdminService extends ServiceBase {
  getUsers() {
    var users = [];

    return this.fetch(`${this.domain}/user`, {
      method: "GET",
      body: null,
    }).then((res) => {
      if (!isEmpty(res)) {
        users = res.reduce((obj, user) => {
          obj[user.userName] = user;
          return obj;
        }, {});
      }

      return users;
    });
  }

  getUser(id) {
    if (isNaN(id)) {
      return Promise.reject(false);
    }

    return this.fetch(`${this.domain}/user/${id}`, {
      method: "GET",
      body: null,
    }).then((res) => {
      return res;
    });
  }

  updateUser(
    profile,
    password,
    passwordQuestion,
    passwordAnswer,
    email,
    mailKey,
    userName
  ) {
    if (isEmpty(profile)) {
      return Promise.reject(false);
    }

    var profilePatch = {};

    if (!isEmpty(password) && profile.password !== password) {
      profilePatch["password"] = password;
    }

    if (
      !isEmpty(passwordQuestion) &&
      profile.passwordQuestion !== passwordQuestion
    ) {
      profilePatch["passwordQuestion"] = passwordQuestion;
    }

    if (!isEmpty(passwordAnswer) && profile.passwordAnswer !== passwordAnswer) {
      profilePatch["passwordAnswer"] = passwordAnswer;
    }

    if (!isEmpty(email) && profile.email !== email) {
      profilePatch["email"] = email;
    }

    if (!isEmpty(mailKey) && profile.mailKey !== mailKey) {
      if (mailKey.length !== 9) {
        var err = {};
        err.message = "Mailkey must be 9 digits.";
        return Promise.reject(err);
      }

      // clean up mailkey entry
      profilePatch["mailKey"] = mailKey
        .trim()
        .replace(/-|_/gi, " ")
        .toUpperCase();
    }

    if (!isEmpty(userName) && profile.userName !== userName) {
      userName = userName.trim();

      const usernameRex = /^[\w]+$/i;
      if (isEmpty(userName.match(usernameRex))) {
        var err = {};
        err.message = "Username must contain only letters and numbers.";
        return Promise.reject(err);
      }

      if (userName.length < 5 || userName.length > 64) {
        var err = {};
        err.message = "Username must be between 5 and 64 characters.";
        return Promise.reject(err);
      }

      profilePatch["userName"] = userName;
    }

    if (!isEmpty(profilePatch)) {
      var patchJSON = JSON.stringify(profilePatch);

      return this.fetch(`${this.domain}/user/${profile.id}`, {
        method: "PATCH",
        body: patchJSON,
      }).then(() => {
        return Promise.resolve(true);
      });
    } else {
      return Promise.resolve(false);
    }
  }

  deleteUser(id) {
    if (isNaN(id)) {
      return Promise.reject(false);
    }

    return this.fetch(`${this.domain}/user/${id}`, {
      method: "DELETE",
      body: null,
    }).then((res) => {
      return res;
    });
  }

  getSTSDownloads() {
    var downloads = [];

    return this.fetch(`${this.domain}/sts`, {
      method: "GET",
      body: null,
    }).then((res) => {
      if (!isEmpty(res)) {
        downloads = res.reduce((obj, sts) => {
          obj[sts.stsNumber] = sts;
          return obj;
        }, {});
      }

      return downloads;
    });
  }

  getSTSDownload(id) {
    if (isNaN(id)) {
      return Promise.reject(false);
    }

    return this.fetch(`${this.domain}/sts/${id}`, {
      method: "GET",
      body: null,
    }).then((res) => {
      let files = {};
      let fileData = res.fileData;

      // need to process the file data so it's easily accessible
      if (!isEmpty(fileData)) {
        files = fileData.reduce((obj, fileData) => {
          obj[fileData.fileType] = fileData;
          return obj;
        }, {});
      }
      res.fileData = files;

      return res;
    });
  }

  addSTSDownload(stsNumber, stsDate, stsTeacherId, stsTitle) {
    var stsAdd = {};

    if (!isEmpty(stsNumber)) {
      stsAdd["stsNumber"] = stsNumber;
    }

    if (!isEmpty(stsDate)) {
      stsAdd["stsDate"] = stsDate;
    }

    if (!isEmpty(stsTitle)) {
      stsAdd["stsTitle"] = stsTitle;
    }

    if (!isNaN(stsTeacherId)) {
      stsAdd["stsTeacherId"] = stsTeacherId;
    }

    if (!isEmpty(stsAdd)) {
      var addJSON = JSON.stringify(stsAdd);
      return this.fetch(`${this.domain}/sts`, {
        method: "POST",
        body: addJSON,
      }).then((res) => {
        let files = {};
        let fileData = res.fileData;

        // need to process the file data so it's easily accessible
        if (!isEmpty(fileData)) {
          files = fileData.reduce((obj, fileData) => {
            obj[fileData.fileType] = fileData;
            return obj;
          }, {});
        }
        res.fileData = files;
        return res;
      });
    } else {
      return Promise.resolve(false);
    }
  }

  updateSTSDownload(sts, stsNumber, stsDate, stsTeacherId, stsTitle) {
    if (isNaN(sts.id)) {
      return Promise.reject(false);
    }

    var stsPatch = {};

    if (!isEmpty(stsNumber) && sts.stsNumber !== stsNumber) {
      stsPatch["stsNumber"] = stsNumber;
    }

    if (!isEmpty(stsDate) && sts.stsDate !== stsDate) {
      stsPatch["stsDate"] = stsDate;
    }

    if (!isEmpty(stsTitle) && sts.stsTitle !== stsTitle) {
      stsPatch["stsTitle"] = stsTitle;
    }

    if (!isNaN(stsTeacherId) && sts.stsTeacherId !== stsTeacherId) {
      stsPatch["stsTeacherId"] = stsTeacherId;
    }

    if (!isEmpty(stsPatch)) {
      var patchJSON = JSON.stringify(stsPatch);
      return this.fetch(`${this.domain}/sts/${sts.id}`, {
        method: "PATCH",
        body: patchJSON,
      }).then(() => {
        return Promise.resolve(true);
      });
    } else {
      return Promise.resolve(false);
    }
  }

  deleteSTSDownload(id) {
    if (isNaN(id)) {
      return Promise.reject(false);
    }

    return this.fetch(`${this.domain}/sts/${id}`, {
      method: "DELETE",
      body: null,
    }).then((res) => {
      return res;
    });
  }

  getSTSUploadForm(stsId, mediaType) {
    if (isNaN(stsId)) {
      return Promise.reject(false);
    }

    if (isEmpty(mediaType)) {
      return Promise.reject(false);
    }

    return this.fetch(
      `${this.domain}/upload?stsId=${stsId}&mediaType=${mediaType}`,
      {
        method: "GET",
        body: null,
      }
    ).then((res) => {
      return res;
    });
  }

  getSTSTeachers() {
    var teachers = [];

    return this.fetch(`${this.domain}/teacher`, {
      method: "GET",
      body: null,
    }).then((res) => {
      if (!isEmpty(res)) {
        teachers = res.reduce((obj, teacher) => {
          obj[teacher.id] = teacher;
          return obj;
        }, {});
      }

      return teachers;
    });
  }

  getSTSTeacher(id) {
    if (isNaN(id)) {
      return Promise.reject(false);
    }

    return this.fetch(`${this.domain}/teacher/${id}`, {
      method: "GET",
      body: null,
    }).then((res) => {
      return res;
    });
  }

  getSTSTeacherTitles() {
    var titles = [];

    return this.fetch(`${this.domain}/teachertitle`, {
      method: "GET",
      body: null,
    }).then((res) => {
      if (!isEmpty(res)) {
        titles = res.reduce((obj, titles) => {
          obj[titles.id] = titles;
          return obj;
        }, {});
      }

      return titles;
    });
  }

  getSTSFileStatuses() {
    var statuses = [];

    return this.fetch(`${this.domain}/filestatus`, {
      method: "GET",
      body: null,
    }).then((res) => {
      if (!isEmpty(res)) {
        statuses = res.reduce((obj, statuses) => {
          obj[statuses.id] = statuses;
          return obj;
        }, {});
      }

      return statuses;
    });
  }

  updateSTSFileStatus(file, status) {
    if (isNaN(file.id)) {
      return Promise.reject(false);
    }

    var filePatch = {};

    if (!isNaN(status) && file.fileStatus !== status) {
      filePatch["fileStatus"] = status;
    }

    if (!isEmpty(filePatch)) {
      var patchJSON = JSON.stringify(filePatch);
      return this.fetch(`${this.domain}/file/${file.id}`, {
        method: "PATCH",
        body: patchJSON,
      }).then(() => {
        return Promise.resolve(true);
      });
    } else {
      return Promise.resolve(false);
    }
  }

  sendReleaseEmail(fileId) {
    if (isNaN(fileId)) {
      return Promise.reject(false);
    }

    return this.fetch(`${this.domain}/newdownload?stsId=${fileId}`, {
      method: "GET",
      body: null,
    }).then((response) => {
      return Promise.resolve(response);
    });
  }

  getClassStreamingLog() {
    var logEntries = [];

    return this.fetch(`${this.domain}/classcode/log`, {
      method: "GET",
      body: null,
    }).then((res) => {
      if (!isEmpty(res)) {
        logEntries = res.reduce((obj, log) => {
          obj[log.id] = log;
          return obj;
        }, {});
      }

      return logEntries;
    });
  }

  toggleClassCode(classCodeId) {
    if (isNaN(classCodeId)) {
      return Promise.reject(false);
    }

    return this.fetch(`${this.domain}/classcode`, {
      method: "PATCH",
      body: JSON.stringify({
        classCodeId: classCodeId,
      }),
    }).then((res) => {
      return Promise.resolve(res);
    });
  }
}
