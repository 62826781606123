import React, { Component } from "react";
import {
  Button,
  Container,
  Form,
  FormGroup,
  FormField,
  Grid,
  Header,
  Input,
  Loader,
} from "semantic-ui-react"; //from 'reactstrap';
import "./Login.css";
import AuthService from "../Authentication/AuthService";
import { withRouter } from "react-router-dom";
import { isEmpty } from "lodash";

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errorMessage: "",
      infoMessage: "",
      error: false,
      isLoading: false,
      redirect: "",
      redirectAfterLogin: "",
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleNav = this.handleNav.bind(this);
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.Auth = new AuthService();

    if (this.props.location.state !== undefined) {
      this.state.infoMessage =
        this.props.location.state.infoMessage !== undefined
          ? this.props.location.state.infoMessage
          : "";

      this.state.redirect =
        this.props.location.state.redirect !== undefined
          ? this.props.location.state.redirect
          : "";

      this.state.redirectAfterLogin =
        this.props.location.state.redirectAfterLogin !== undefined
          ? this.props.location.state.redirectAfterLogin
          : "";
    }
  }

  componentWillMount() {
    if (!isEmpty(this.state.redirect)) {
      setTimeout(() => {
        window.location.href = this.state.redirect;
      }, 10000);
    }
  }

  handleFormSubmit(e) {
    e.preventDefault();
    this.setState({
      isLoading: true,
      error: false,
      errorMessage: "",
    });
    this.Auth.login(this.state.username, this.state.password)
      .then(() => {
        this.props.history.push(
          this.state.redirectAfterLogin ? this.state.redirectAfterLogin : "/"
        );
      })
      .catch((err) => {
        this.setState({
          errorMessage: err.message,
          infoMessage: "",
          isLoading: false,
        });
      });
  }

  handleChange(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  handleNav(e) {
    this.props.history.push("/" + e);
  }

  render() {
    return (
      <div className="app flex-row login">
        <Container>
          <Grid className="centered">
            <Grid.Row>
              <Grid.Column className="title" textAlign="center">
                <Header className="titleStyle">
                  The Way International Portal
                </Header>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>
                <Form
                  className="formStyle"
                  error={this.state.error}
                  onSubmit={this.handleFormSubmit}
                >
                  <Header className="formTitleStyle" textAlign="center">
                    Please Sign In
                  </Header>
                  <FormGroup>
                    <FormField error={this.state.error}>
                      <Input
                        className="inputStyle"
                        type="text"
                        name="username"
                        placeholder="Enter Username"
                        onChange={this.handleChange}
                      />
                    </FormField>
                  </FormGroup>
                  <FormGroup>
                    <FormField error={this.state.error}>
                      <Input
                        className="inputStyle"
                        type="password"
                        name="password"
                        placeholder="Enter Password"
                        onChange={this.handleChange}
                      />
                    </FormField>
                  </FormGroup>
                  <Button
                    className="buttonStyle"
                    primary
                    onClick={this.onSubmit}
                    loading={this.state.isLoading}
                  >
                    Sign In
                  </Button>
                </Form>
              </Grid.Column>
            </Grid.Row>
            {this.state.errorMessage !== "" && (
              <Grid.Row>
                <Grid.Column>
                  <p className="loginError">{this.state.errorMessage}</p>
                </Grid.Column>
              </Grid.Row>
            )}
            {this.state.infoMessage !== "" && (
              <Grid.Row>
                <Grid.Column>
                  <p className="loginInfo">
                    {this.state.infoMessage}
                    {!isEmpty(this.state.redirect) && (
                      <>
                        <br />
                        <br />
                        <Loader
                          size="small"
                          active={!isEmpty(this.state.redirect)}
                          inline
                        >
                          Redirecting you back...
                        </Loader>
                      </>
                    )}
                  </p>
                </Grid.Column>
              </Grid.Row>
            )}
            <Grid.Row>
              <Grid.Column>
                <Form className="formStyle">
                  <Header className="formTitleStyle" textAlign="center">
                    New to the Portal?
                  </Header>
                  <Button
                    color="green"
                    onClick={() => this.handleNav("register")}
                    className="buttonStyle"
                  >
                    Register
                  </Button>
                </Form>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column textAlign="center">
                <a href="" onClick={() => this.handleNav("forgot")}>
                  Forgot password? Click here.
                </a>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Container>
      </div>
    );
  }
}

export default withRouter(Login);
