import ServiceBase from "../../ServiceBase";
import { isEmpty } from "lodash";

export default class ProfileService extends ServiceBase {
  // Initializing important variables
  constructor(domain) {
    super(domain);
    this.getProfile = this.getProfile.bind(this);
    this.updateProfile = this.updateProfile.bind(this);
  }

  getProfile() {
    return this.fetch(`${this.domain}/profile`, {
      method: "GET",
      body: null,
    }).then((res) => {
      return res;
    });
  }

  updateProfile(profile, password, passwordQuestion, passwordAnswer, email) {
    if (isEmpty(profile)) {
      return Promise.reject(false);
    }

    var profilePatch = {};

    if (!isEmpty(password) && profile.password !== password) {
      profilePatch["password"] = password;
    }

    if (
      !isEmpty(passwordQuestion) &&
      profile.passwordQuestion !== passwordQuestion
    ) {
      profilePatch["passwordQuestion"] = passwordQuestion;
    }

    if (!isEmpty(passwordAnswer) && profile.passwordAnswer !== passwordAnswer) {
      profilePatch["passwordAnswer"] = passwordAnswer;
    }

    if (!isEmpty(email) && profile.email !== email) {
      profilePatch["email"] = email;
    }

    if (!isEmpty(profilePatch)) {
      var patchJSON = JSON.stringify(profilePatch);

      return this.fetch(`${this.domain}/profile`, {
        method: "PATCH",
        body: patchJSON,
      }).then(() => {
        return Promise.resolve(true);
      });
    } else {
      return Promise.resolve(false);
    }
  }
}
