import React from "react";
import { Dropdown, Button, Modal, Icon } from "semantic-ui-react";
import { DownloadSupportButton } from "./DownloadSupportButton";

const userGuideUrl =
  "https://d2lkknuzmn43ka.cloudfront.net/portal/docs/portal_user_guide.pdf";

export class HelpButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
    };
  }

  downloadHelp() {
    window.location.href = userGuideUrl;
  }

  opener(setValue) {
    // If no argument given, default to toggling the open state.
    const open = setValue === undefined ? !this.state.open : setValue;
    this.setState({ open: open });
  }

  render() {
    // Extract the "as", defaulting to a button
    const { as: Component = Button, onClick, children, ...props } = this.props;
    switch (Component) {
      case Dropdown:
        return (
          <Dropdown
            icon="help"
            floating
            button
            className="icon inverted"
            basic
            direction="left"
            compact
            onClick={onClick}
            {...props}
          >
            <Dropdown.Menu>
              <HelpButton as={Dropdown.Item} />
              <DownloadSupportButton />
              {children}
            </Dropdown.Menu>
          </Dropdown>
        );

      case Dropdown.Item:
        return (
          <Dropdown.Item
            icon="help"
            text="Help"
            onClick={onClick || this.downloadHelp}
            {...props}
          />
        );
      // Button is the default.
      case Button:
        return (
          <Modal
            className="modalHelpOuter"
            open={this.state.open}
            onOpen={() => this.opener(true)}
            onClose={() => this.opener(false)}
            trigger={
              <Button
                basic
                inverted
                compact
                icon="help"
                onClick={onClick}
                {...props}
              />
            }
          >
            <Modal.Header>Portal User Guide</Modal.Header>
            <Modal.Content className="modalHelpContent">
              <Modal.Description className="modalHelpDescription">
                <iframe src={userGuideUrl} width="100%" height="100%"></iframe>
              </Modal.Description>
            </Modal.Content>
            <Modal.Actions>
              <Button
                as="a"
                href="mailto:downloadsupport@theway.org"
                icon
                color="purple"
                labelPosition="left"
                type="button"
                floated="left"
              >
                <Icon name="mail" />
                Contact Download Support
              </Button>
              <Button onClick={() => this.opener()}>Done</Button>
            </Modal.Actions>
          </Modal>
        );

      // Otherwise, throw up
      default:
        throw `Unsupported type for "as" property: ${Component}`;
    }
  }
}
