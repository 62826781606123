import decode from "jwt-decode";
import { API_BASE_URL } from "config";
import { debounce } from "lodash";

const _expirationRedirectHandlers = [];

export default class ServiceBase {
  // Initializing important variables
  constructor(domain) {
    this.domain = domain || API_BASE_URL; // API server domain
    this.fetch = this.fetch.bind(this); // React binding stuff
    // Check regularly to see if our session has expired.
    this.debounceExpiration = debounce(this.expirationRedirect, 30 * 1000);
  }

  getToken() {
    // Retrieves the user token from localStorage
    return localStorage.getItem("id_token");
  }

  isTokenExpired(token) {
    try {
      const decoded = decode(token);
      if (decoded.exp < Date.now() / 1000) {
        // Checking if token is expired. N
        return true;
      } else return false;
    } catch (err) {
      return false;
    }
  }

  loggedIn() {
    // Checks if there is a saved token and it's still valid
    const token = this.getToken(); // Getting token from localstorage
    return !!token && !this.isTokenExpired(token); // handwaiving here
  }

  getUsername() {
    const token = this.getToken();
    return decode(token).username;
  }

  /*
  Kicks you back to the login page if your token has expired. 
  Otherwise, restarts the counter that calls this method after the configured time.
  */
  expirationRedirect() {
    this.loggedIn()
      ? this.debounceExpiration()
      : _expirationRedirectHandlers.forEach((handler) => handler());
  }

  /*
  Adds a function to be called when the login session ends.
  @param fn Function to call when the login session ends.
  */
  addLogoutHandler(fn) {
    _expirationRedirectHandlers.push(fn);
    if (this.loggedIn()) this.debounceExpiration();
  }

  fetch(url, options, json = true) {
    // performs api calls sending the required authentication headers
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
    };

    // Setting Authorization header
    // Authorization: Bearer xxxxxxx.xxxxxxxx.xxxxxx
    if (this.loggedIn()) {
      headers["Authorization"] = "Bearer " + this.getToken();
      this.debounceExpiration();
    }

    return fetch(url, {
      headers,
      ...options,
    }).then((response) => this._checkStatus(response, json));
  }

  _checkStatus(response, json) {
    if (json) {
      return response.json().then((responseInJson) => {
        // checks if response is properly authorized. status set in server.
        if (response.status >= 200 && response.status < 300) {
          return responseInJson; // This will end up in SUCCESS part
        } else {
          return Promise.reject(responseInJson);
        }
      });
    } else {
      if (response.status >= 200 && response.status < 300) {
        return response; // This will end up in SUCCESS part
      } else {
        return Promise.reject(response);
      }
    }
  }
}
